import React from 'react'
import ClockLoader from 'react-spinners/ClockLoader'
import PropTypes from 'prop-types'

const UserVerification = props => {
  const { user } = props

  return (
    <>
      <style type="text/css">
        {`
          .UserVerificationIndicator {
              height: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              flex-direction: column;
          }
        `}
      </style>
      <div className={'UserVerificationIndicator'}>
        <div>The <strong>{user}</strong> account </div>
        <div>is waiting to be verified by Validato.io support</div>
        <div>
          <ClockLoader
            color={'#DC3545'}
            loading={true}
            size={40}
            css={{ display: 'block' }} />
        </div>
      </div>
    </>
  )
}

UserVerification.propTypes = {
  user: PropTypes.string
}

export default UserVerification
