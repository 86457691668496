import { Col, Row } from 'react-bootstrap'
import * as React from 'react'
import PropTypes from 'prop-types'

const LoggedUser = props => {
  const { tokenParsed } = props

  return (
    <>
      <h2>Logged user</h2>
      <Row>
        <Col
          sm="3"
          className="bold">Sub:
        </Col>
        <Col sm="5">{tokenParsed?.sub ?? 'N/A'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          UserName:
        </Col>
        <Col sm="5">{tokenParsed?.preferred_username ?? 'N/A'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          Email:
        </Col>
        <Col sm="5">{tokenParsed?.email ?? 'N/A'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          First name:
        </Col>
        <Col sm="5">{tokenParsed?.given_name ?? 'N/A'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          Last name:
        </Col>
        <Col sm="5">{tokenParsed?.family_name ?? 'N/A'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          Email Verified:
        </Col>
        <Col sm="5">{tokenParsed?.email_verified ? 'YES' : 'NO'}</Col>
      </Row>
      <Row>
        <Col
          sm="3"
          className="bold">
          Exp:
        </Col>
        <Col sm="5">
          {new Date(tokenParsed?.exp * 1000).toLocaleDateString() + ' ' +
          new Date(tokenParsed?.exp * 1000).toLocaleTimeString()}
        </Col>
      </Row>
    </>
  )
}

LoggedUser.propTypes = {
  tokenParsed: PropTypes.object
}

export default LoggedUser
