import React from 'react'
import Keycloak from 'keycloak-js'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AppRouter } from './routes'
import { reactPlugin } from './utils/AppInsights'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import './index.css'

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
})

const keycloakProviderInitConfig = {
  onLoad: 'login-required'
}

class App extends React.PureComponent {
  render () {
    return (
      <AppInsightsContext.Provider
        value={reactPlugin}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initConfig={keycloakProviderInitConfig}
          onEvent={this.onKeycloakEvent}
          onTokens={this.onKeycloakTokens}>
          <AppRouter />
        </ReactKeycloakProvider>
      </AppInsightsContext.Provider>
    )
  }
}

export default App
