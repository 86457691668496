import React from 'react'
import HashLoader from 'react-spinners/HashLoader'

const LoadingIndicator = () => {
  return (
    <>
      <style type="text/css">
        {`
          .LoadingIndicator {
              height: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              flex-direction: column;
          }          
        `}
      </style>
      <div className={'LoadingIndicator'}>
        <div>Initializing Keyclok integration...</div>
        <div>
        <HashLoader
          color={'#3BAA35'}
          loading={true}
          size={40}
          css={{ display: 'block' }} />
        </div>
      </div>
    </>
  )
}

export default LoadingIndicator
