import { Button, Modal } from 'react-bootstrap'
import * as React from 'react'
import PropTypes from 'prop-types'

const CustomModal = props => {
  const { show, pageReload, onHide, title, error = false } = props
  return (
    <>
      <style type="text/css">
        {`
          .modal-header {
            background-color: #3BAA35;
            color: #fff;
          }          
          .error { 
            background-color: #DC3545;
          }
        `}
      </style>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onHide}>
        <Modal.Header
          closeButton
          className={error ? 'error' : ''}>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          {pageReload && (
            <Button
              onClick={pageReload}
              variant="validato">Reload page</Button>
          )
          }
          <Button
            onClick={props.onHide}
            variant={error ? 'danger' : 'secondary'}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
CustomModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  pageReload: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  error: PropTypes.bool
}

export default CustomModal
