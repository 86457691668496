import { Button, Col, Overlay, Row, Tooltip } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as React from 'react'
import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useKeycloak } from '@react-keycloak/web'

const Tokens = props => {
  const { keycloak } = useKeycloak()
  const [copied, setCopied] = useState('')

  const targetToken = useRef(null)
  const targetRefreshToken = useRef(null)

  const onCopyText = (type = 'token') => {
    setCopied(type)
    setTimeout(() => {
      setCopied('')
    }, 1000)
  }

  return (
    <>
      <h2>Tokens</h2>
      <Row>
        <Col sm="2"><strong>Token</strong></Col>
        <Col
          sm="8"
          className="wrapall">
          <span>{keycloak?.token ?? 'NA'}</span>
        </Col>
        <Col sm="2">
          <CopyToClipboard
            text={keycloak?.token ?? 'NA'}
            onCopy={() => onCopyText('token')}>
            <div>
              <Button
                ref={targetToken}
                variant="validato">
                Copy
              </Button>
              <Overlay
                target={targetToken.current}
                show={copied === 'token'}>
                {props => (
                  <Tooltip
                    id="overlay-tooltip-token"
                    {...props}>
                    Copied!!!
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </CopyToClipboard>
        </Col>
      </Row>
      <Row>
        <Col sm="2"><strong>Refresh Token</strong></Col>
        <Col
          sm="8"
          className="wrapall">
          <span>{keycloak?.refreshToken ?? 'NA'}</span>
        </Col>
        <Col sm="2">
          <CopyToClipboard
            text={keycloak?.refreshToken ?? 'NA'}
            onCopy={() => onCopyText('refreshToken')}>
            <div>
              <Button
                ref={targetRefreshToken}
                variant="validato">Copy</Button>
              <Overlay
                target={targetRefreshToken.current}
                show={copied === 'refreshToken'}>
                {props => (
                  <Tooltip
                    id="overlay-tooltip-token"
                    {...props}>
                    Copied!!!
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </CopyToClipboard>
        </Col>
      </Row>
    </>
  )
}

Tokens.propTypes = {
  token: PropTypes.string,
  refreshToken: PropTypes.string
}

export default Tokens
