import * as React from 'react'
import { useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import HomePage from '../pages/Home'
import LoginPage from '../pages/Login'

import { PrivateRoute } from './utils'
import LoadingIndicator from '../components/LoadingIndicator'
import UserVerification from '../components/UserVerification'

export const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak()
  const [userVerified] = useState(false)
  const [activeActive] = useState(false)

  if (!initialized) {
    return <div><LoadingIndicator /></div>
  } else if (userVerified && !activeActive) {
    return <div><UserVerification user={keycloak.tokenParsed.preferred_username} /></div>
  }

  return (
    <Router>
      <PrivateRoute
        path="/"
        component={HomePage} />
      <Route
        path="/login"
        component={LoginPage} />
    </Router>
  )
}
