import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import PropTypes from 'prop-types'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { keycloak } = useKeycloak()

  return (
        <Route
            {...rest}
            render={props =>
              keycloak.authenticated
                ? (
                    <Component {...props} />
                  )
                : (
                    <Redirect
                        to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }} />
                  )
            } />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.string
}
