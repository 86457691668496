import * as React from 'react'
import { useCallback, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { appInsights } from '../utils/AppInsights'
import { CustomModal } from '../components/'
import Tokens from '../components/home/Tokens'
import LoggedUser from '../components/home/LoggedUser'

const HomePage = () => {
  appInsights.trackPageViewPerformance({ name: 'Home page' })

  const { keycloak } = useKeycloak()
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => setShowModal(false)
  const handleReload = () => window.location.reload()

  const refresh = useCallback(() => {
    keycloak.updateToken(5).then(refreshed => {
      if (refreshed) {
        setMessage('Token was successfully refreshed')
      } else {
        setMessage('Token is still valid')
      }
    })
      .catch(() => {
        setMessage('Failed to refresh the token, or the session has expired')
      })
    setShowModal(true)
  }, [keycloak])

  return (
    <>
      <style type="text/css">
        {`
          .btn-validato {
            background-color: #3BAA35;
            color: white;
          }
          .btn-validato:hover {
            color: white;
          }
          h2 {
            padding-bottom: 0.5em;
            border-bottom: 2px #E6E6E6 solid;
            margin-bottom: 1em;
          }          
        `}
      </style>
      <CustomModal
        show={showModal}
        onHide={handleClose}
        pageReload={handleReload}
        message={message}
        title={'Refresh token'} />

      <Container className="p-3">
        {(!!keycloak?.authenticated && (
          <>
            <Jumbotron>
              <h1>Validato user authentication test</h1>
              <Row className="align-items-center">
                <Col sm="3">User is <span className={'ok'}>AUTHENTICATED</span></Col>
                <Col sm="4">
                  <Button
                    onClick={() => keycloak.logout()}
                    variant="validato">Logout</Button>{' '}
                  <Button
                    onClick={refresh}
                    variant="validato">Refresh</Button>
                </Col>
              </Row>
            </Jumbotron>

            <LoggedUser tokenParsed={keycloak?.tokenParsed} />

            <Tokens />

          </>
        )) || <div>User is <span className={'error'}>NOT AUTHENTICATED</span></div>
        }
      </Container>
    </>
  )
}

export default HomePage
