import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory({ basename: '' })
const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_INSIGHTS_KEY,
    // 0: ALL console logging off 1: logs to console: severity >= CRITICAL 2: logs to console: severity >= WARNING
    loggingLevelConsole: 2,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})

const trackError = message => {
  appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error })
}

appInsights.loadAppInsights()
appInsights.trackPageView({ name: 'oauth-test' })

export { reactPlugin, appInsights, trackError }
